<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_inspecciones_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Postulaciones
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre"
                              v-model="form.inspeccion"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre"
                              >N Inspeccion</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="sitio"
                              v-model="form.fecha"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="sitio"
                              >Fecha</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.formato"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Formato</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="obvs"
                              v-model="form.transportadora"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="obvs"
                              >Transportadora</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="observaciones"
                              v-model="form.vehiculo"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="observaciones"
                              >Vehículo / Equipo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="planeaciones_volumenes"
                              v-model="form.conductor"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="planeaciones_volumenes"
                              >Conductor / Operador</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="row form-group">
                    <label class="col-md-5" style="font-size: 1.2em"
                      >Formato de Inspección</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.formato_inspeccion"
                      placeholder="Formato Inspección"
                      label="nombre"
                      style="font-size: 15px; background-color: #fff"
                      :options="listasForms.formatos_inspeccion"
                      @search="buscarFormatosInspeccion"
                      @input="selectFormato()"
                      multiple
                      class="form-control form-control-sm p-0 col-md-7"
                    ></v-select>
                  </div>
                  <div class="row form-group">
                    <label class="col-md-5" style="font-size: 1.2em"
                      >Transportadora</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.empresa"
                      placeholder="Empresa"
                      label="razon_social"
                      :options="listasForms.empresas"
                      style="font-size: 15px; background-color: #fff"
                      @input="selectEmpresas()"
                      multiple
                      class="form-control form-control-sm p-0 col-md-7"
                    ></v-select>
                  </div>
                  <div class="row form-group">
                    <label class="col-md-5" style="font-size: 1.2em"
                      >Tipo Servicio</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.tipo_servicio"
                      placeholder="Tipo Servicio"
                      label="descripcion"
                      style="font-size: 15px; background-color: #fff"
                      :options="listasForms.tipos_servicios"
                      @input="selectTipoServicio()"
                      class="form-control form-control-sm p-0 col-md-7"
                    ></v-select>
                  </div>
                  <div class="row form-group" v-if="filtros.tipo_servicio">
                    <label class="col-md-5" style="font-size: 1.2em">
                      {{
                        filtros.tipo_servicio_id == 1 ? "Cabezote" : "Equipo"
                      }}
                    </label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.cabezote"
                      placeholder="Placa"
                      label="placa"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.cabezotes"
                      :filterable="false"
                      @search="buscarCabezotes"
                      @input="selectVehiculo()"
                      multiple
                    ></v-select>
                  </div>
                  <div class="row form-group" v-if="filtros.tipo_servicio">
                    <label class="col-md-5" style="font-size: 1.2em">
                      {{
                        filtros.tipo_servicio_id == 1 ? "Conductor" : "Operario"
                      }}
                    </label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.conductor"
                      :placeholder="
                        filtros.tipo_servicio_id == 1 ? 'Conductor' : 'Operario'
                      "
                      label="nombre"
                      style="font-size: 15px; background-color: #fff"
                      :options="listasForms.conductores"
                      @search="buscarConductor"
                      @input="selectConductor()"
                      multiple
                      class="form-control form-control-sm p-0 col-md-7"
                    ></v-select>
                  </div>
                  <div class="row form-group">
                    <label
                      for="fecha_inicial"
                      class="col-md-5"
                      style="font-size: 1.2em"
                      >Fecha Inicial</label
                    >
                    <input
                      type="date"
                      v-model="filtros.fecha_inicial"
                      class="form-control form-control-sm col-md-7"
                      @input="validaFechasForm"
                    />
                  </div>
                  <div class="row form-group">
                    <label
                      for="fecha_final"
                      class="col-md-5"
                      style="font-size: 1.2em"
                      >Fecha Final</label
                    >
                    <input
                      type="date"
                      v-model="filtros.fecha_final"
                      class="form-control form-control-sm col-md-7"
                      @input="validaFechasForm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('cs.inspecciones.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "CsInspeccionesExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      listasForms: {
        empresas: [],
        formatos_inspeccion: [],
        tipos_servicios: [],
        cabezotes: [],
        conductores: [],
      },
      filtros: {
        formato_inspeccion: [],
        sitio_id: [],
        formato_inspeccion_id: [],
        tipo_servicio: null,
        tipo_servicio_id: [],
        cabezote: [],
        cabezote_id: [],
        conductor: [],
        conductor_id: [],
        empresa: [],
        empresa_id: [],
        fecha_inicial: null,
        fecha_final: null,
      },
      form: {
        inspeccion: true,
        vehiculo: true,
        transportadora: true,
        fecha: true,
        formato: true,
        conductor: true,
      },
    };
  },

  methods: {
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/inspecciones/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },
    selectBloque() {
      this.filtros.bloque_id = this.filtros.bloque.map((e) => e.id);
    },
    getTransportadoras() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },
    selectEmpresas() {
      this.filtros.empresa_id = this.filtros.empresa.map((e) => e.id);
    },
    getTiposServicios() {
      axios.get("/api/lista/130").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },
    selectTipoServicio() {
      this.listasForms.cabezotes = [];
      this.listasForms.conductores = [];
      this.filtros.cabezote = [];
      this.filtros.cabezote_id = [];
      this.filtros.conductor = [];
      this.filtros.conductor_id = [];
      this.filtros.tipo_servicio_id = null;
      if (this.filtros.tipo_servicio) {
        this.filtros.tipo_servicio_id = this.filtros.tipo_servicio.numeracion;
      }
    },
    validaFechasForm() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        const fecha_menor = new Date(this.filtros.fecha_inicial);
        const fecha_mayor = new Date(this.filtros.fecha_final);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio no puede ser mayor a la Fecha Fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    buscarFormatosInspeccion(search, loading) {
      if (search != "") {
        // Pregunto si es preoperacional
        // Pregunto si tiene un punto de gestión asignado
        // formatos por tipo de sitio
        let me = this;
        loading(true);
        var url = "api/cs/inspecciones/formatos?nombre=" + search;
        axios
          .get(url, {})
          .then(function(response) {
            me.listasForms.formatos_inspeccion = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    selectFormato() {
      this.filtros.formato_inspeccion_id = this.filtros.formato_inspeccion.map(
        (e) => e.id
      );
    },
    buscarCabezotes(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);

        let url = "api/cs/inspecciones/buscarcabezote?placa=" + search;
        if (this.filtros.tipo_servicio_id != 1) {
          url = "api/cs/inspecciones/buscarequipo?placa=" + search;
        }

        axios
          .get(url)
          .then(function(response) {
            me.listasForms.cabezotes = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    selectVehiculo() {
      this.filtros.cabezote_id = this.filtros.cabezote.map((e) => e.id);
    },
    buscarConductor(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);

        let url = "api/admin/conductores/lista?nombre=" + search;
        if (this.filtros.tipo_servicio_id != 1) {
          url = "api/cs/inspecciones/buscaroperario?nombre=" + search;
        }

        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.conductores = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    selectConductor() {
      this.filtros.conductor_id = this.filtros.conductor.map((e) => e.id);
    },
  },
  mounted() {
    this.getBloques();
    this.getTransportadoras();
    this.getTiposServicios();
  },
};
</script>
